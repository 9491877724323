import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery, UseInfiniteQueryOptions } from 'react-query';
import { fieldNotesQueries } from '@esub-engineering/react-common-lib';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { searchLimit } from '../../common/api';
import { openToast } from '../../common/redux/toast';
import {
  IFieldNote,
  IFieldNoteList,
  TUseSearchFieldNotesProps,
  ISearchFieldNotesResult,
} from '../types';
import {
  IUseGraphQueryProps,
  useGraphMutation,
  useInfiniteQuery,
  useInfiniteQueryList,
  useToast,
  useGraphQuery,
  IUseGraphMutationProps,
} from '../../common/hooks';
import { searchContacts } from '../api/queries';

const ATTACHMENT_QUERY_LIMIT = 50;
const DOCUMENT_QUERY_LIMIT = 50;

// TODO: update this to follow current pattern using the common hooks
// TODO: would be good to split these up and test individually

interface ISearchQuery extends Partial<IUseGraphQueryProps> {
  variables: {
    term: string;
  };
}

export const useFieldNoteList = (projectId: string | undefined) => {
  const client = useGraphQLClient();
  const dispatch = useDispatch();
  const { key, listFieldNotes } = fieldNotesQueries;
  const queryFieldNotes = useCallback(
    () => client!.request<{ listFieldNotes: IFieldNoteList }>(listFieldNotes, { projectId }, {}),
    [client, listFieldNotes, projectId]
  );

  return useQuery<{ listFieldNotes: IFieldNoteList }, Error>(key, queryFieldNotes, {
    onError: () => {
      dispatch(
        openToast({
          type: 'error',
          message: 'Unable to get field notes log',
        })
      );
    },
    refetchOnWindowFocus: false,
  });
};

export const useSearchFieldNotes = ({
  direction = 'DESC',
  filter,
  limit = searchLimit,
  page,
  sortBy = 'DATE',
  term,
  options,
}: TUseSearchFieldNotesProps) => {
  const client = useGraphQLClient();
  const { searchKey, searchFieldNotes } = fieldNotesQueries;
  const queryFieldNotes = useCallback(
    ({ pageParam }) =>
      client!.request<{ searchFieldNotes: ISearchFieldNotesResult }>(
        searchFieldNotes,
        {
          direction,
          filter,
          limit,
          page: pageParam || page,
          sortBy,
          term,
        },
        {}
      ),
    [client, direction, filter, limit, page, searchFieldNotes, sortBy, term]
  );

  return useInfiniteQuery({
    queryName: 'searchFieldNotes',

    // NOTE: this is how reqctu query works but they have an error in their own typescrip
    // that screams at you for using an array and we can and shoudl ignore it.
    // @ts-ignore
    queryKey: [searchKey, direction, filter, limit, page, sortBy, term],
    query: queryFieldNotes,
    errMessage: 'Unable to load Field Notes',
    options,
  });
};

export const useFieldNoteAttachments = (
  fieldNoteId: string | undefined,
  options: UseInfiniteQueryOptions<any, unknown, any, any, string> | undefined = {}
) => {
  const client = useGraphQLClient();

  const query = useCallback(
    ({ pageParam }) =>
      client!.request(fieldNotesQueries.getFieldNoteAttachments, {
        input: {
          fieldNoteId,
          cursor: pageParam,
          limit: ATTACHMENT_QUERY_LIMIT,
        },
      }),
    [fieldNoteId, client]
  );

  return useInfiniteQueryList({
    queryName: 'getFieldNoteAttachments',
    // @ts-ignore
    queryKey: [fieldNotesQueries.key, fieldNoteId, 'getFieldNoteAttachments'],
    query,
    errMessage: 'Unable to get attachments ',
    options,
  });
};

export const useFieldNoteDocuments = (
  fieldNoteId: string | undefined,
  options: UseInfiniteQueryOptions<any, unknown, any, any, string> | undefined = {}
) => {
  const client = useGraphQLClient();

  const query = useCallback(
    ({ pageParam }) =>
      client!.request(fieldNotesQueries.getFieldNoteDocs, {
        input: {
          fieldNoteId,
          cursor: pageParam,
          limit: DOCUMENT_QUERY_LIMIT,
        },
      }),
    [fieldNoteId, client]
  );

  return useInfiniteQueryList({
    queryName: 'getFieldNoteDocs',
    // @ts-ignore
    queryKey: [fieldNotesQueries.key, fieldNoteId, 'getFieldNoteDocs'],
    query,
    errMessage: 'Unable to get documents',
    options,
  });
};

export const useFieldNote = (fieldNoteId: string | undefined) => {
  const client = useGraphQLClient();
  const { key, getFieldNote } = fieldNotesQueries;

  const queryFieldNote = useCallback(
    () =>
      client!.request<{ getFieldNote: IFieldNote }>(getFieldNote, {
        id: fieldNoteId,
      }),
    [client, fieldNoteId, getFieldNote]
  );

  return useQuery<{ getFieldNote: IFieldNote }, Error>([key, { id: fieldNoteId }], queryFieldNote);
};

export const useFieldNoteCreation = ({
  ...rest
}: Omit<IUseGraphMutationProps<any, Error, any, unknown>, 'gql'>) => {
  const { openErrorToast } = useToast();
  const { key, createFieldNote, searchKey } = fieldNotesQueries;

  return useGraphMutation({
    key,
    gql: createFieldNote,
    delayKey: searchKey,
    delay: 4000,
    onError: () => {
      openErrorToast('Unable to save Field Note');
    },
    ...rest,
  });
};

export const useFieldNoteUpdate = ({
  ...rest
}: Omit<IUseGraphMutationProps<any, Error, any, unknown>, 'gql'>) => {
  const { openErrorToast } = useToast();
  const { key, updateFieldNote, searchKey } = fieldNotesQueries;

  return useGraphMutation({
    key,
    gql: updateFieldNote,
    delayKey: searchKey,
    delay: 4000,
    onError: () => {
      openErrorToast('Unable to save Field Note');
    },
    ...rest,
  });
};

export const useFieldNotePdf = ({ ...rest } = {}) =>
  useGraphMutation({
    key: fieldNotesQueries.key,
    gql: fieldNotesQueries.createPdfForFieldNote,
    ...rest,
  });

export const useFieldNoteEmail = ({ ...rest } = {}) =>
  useGraphMutation({
    key: fieldNotesQueries.key,
    gql: fieldNotesQueries.sendEmailForFieldNoteV2,
    ...rest,
  });

export const useFieldNoteDelete = (cb?: () => void) => {
  const { openErrorToast, openSuccessToast } = useToast();
  return useGraphMutation({
    key: fieldNotesQueries.key,
    gql: fieldNotesQueries.deleteFieldNote,
    delayKey: fieldNotesQueries.searchKey,
    onSuccess: () => {
      openSuccessToast('Deleted successfully.');
      if (cb) cb();
    },
    onError: () => {
      openErrorToast('Unable to delete Field Note');
    },
  });
};

export const useSearchContacts = ({ ...rest }: ISearchQuery) =>
  useGraphQuery({
    key: ['searchContacts', rest.variables.term, JSON.stringify(rest.variables)],
    gql: searchContacts,
    ...rest,
  });
