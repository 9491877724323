import format from 'date-fns/format';
import { inputDateFormatMap } from '@esub-engineering/react-common-lib';
import { DateFormatFn, UserDateDisplayPrefFormatMap } from './types';

// NOTE: there is some code duplication here as we move from libs/common/utils
// use these date utils and formatters moving forward as the libs implementation has become unmanageable

/**
 * Formats used for date display purposes (reports, views, etc)
 * @note This should be all we need to do in the future with a few exceptions
 */
export const displayDateFormatMap: UserDateDisplayPrefFormatMap = {
  mdy: 'MM/dd/yyyy',
  dmy: 'dd/MM/yyyy',
  ymd: 'yyyy/MM/dd',
};

export const reverseDisplayDateFormatMap: UserDateDisplayPrefFormatMap = {
  'MM/dd/yyyy': 'mdy2',
  'dd/MM/yyyy': 'dmy2',
  'yyyy/MM/dd': 'ymd2',
};

/**
 * Attempt to resolve a date fro ma string otherwise return null
 */
export const resolveDate = (dateString?: string): Date | null => {
  if (!dateString) return null;

  return !Number.isNaN(Date.parse(dateString)) ? new Date(dateString) : null;
};

/**
 * Format date string for pdf reports
 */
export const getFormattedDate: DateFormatFn = (
  date,
  { dateDisplayPref = 'mdy', map = displayDateFormatMap } = {}
) => {
  // attempt to resolve date, otherwise return null
  const resolvedDate = date ? resolveDate(date.toString()) : null;
  if (!resolvedDate) return null;

  // attempt to get format from mapping, otherwise return null
  const pattern = map[dateDisplayPref];
  if (!pattern) return null;

  const formattedDateString = format(resolvedDate, pattern);

  // convert to string and trim extra space
  return `${formattedDateString}`.trim();
};

const getReverseInputMapping = () =>
  Object.entries(inputDateFormatMap).reduce(
    (acc: { [k: keyof typeof inputDateFormatMap]: string }, curr) => {
      const [prop, val] = curr;

      acc[val] = acc[val] || prop;

      return acc;
    },
    {}
  );

/**
 * Reverse mapping of input date formatting
 * Date utils expect an alias for desired date format such as 'mdy or mdy2
 * This method provides proper alias mapping from 'MM/dd/yyyy' to 'mdy2' for example
 */
export const getDateFormatAliasFromPreference = (datePreference: string) => {
  const fallback = 'mdy2';
  const reverseMapping = getReverseInputMapping();

  return reverseMapping[datePreference] || fallback;
};
