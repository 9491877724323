import React, { useState } from 'react';
import { ControlledContactTextField } from '@esub-engineering/common-containers';
import { IContactSelectorProps } from './type';
import { useSearchContacts } from '../../../fieldNote/hooks';

export const ContactSelector = React.memo(
  ({ control, name, label, id, isEmail = false, multiple = true, ...rest }: IContactSelectorProps) => {
    const [searchCriteria, setSearchCriteria] = useState<string>(
      'This field should not be empty as first'
    );

    const { data: contactsFound, isLoading: isLoadingContact } = useSearchContacts({
      variables: { term: searchCriteria },
    });

    const filterOptions = (data: any) => {
      // @ts-ignore
      const filtered = data?.filter((d) => d.document.email !== null);
      return filtered;
    };

    const parseOptions = (data: any) =>
      data?.map((item: any) => {
        const { name: dName, email: dEmail, id: dId } = item.document;
        return {
          id: dId,
          name: dName,
          email: dEmail,
        };
      }) || [];

    const options = [...parseOptions(filterOptions(contactsFound?.searchContacts?.items))];

    return (
      <ControlledContactTextField
        fetchingOptions={isLoadingContact}
        name={name}
        control={control}
        label={label}
        id={id}
        fullWidth
        setSearchCriteria={setSearchCriteria}
        options={options}
        isEmail={isEmail}
        multiple={multiple}
        {...rest}
      />
    );
  }
);
