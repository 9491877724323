import {
  IAttachmentLandingZoneState,
  TAttachmentLandingZoneFile,
} from '@esub-engineering/react-component-library';
import { useCallback, useState } from 'react';
import { AttachmentItem, DocumentItem } from '../../api';
import { useGraphMutation } from '../useGraphMutation/index';
import {
  InputCreationDefault,
  InputDeletionDefault,
  InputUpdateDefault,
  IuseAttachmentProps,
  ResponseCreationDefault,
  TFlagAttachments,
  TFlagsKey,
} from './types';

export const useAttachment = <
  InputCreation extends InputCreationDefault,
  ReturnCreation extends ResponseCreationDefault,
  InputDelete extends InputDeletionDefault,
  InputUpdate extends InputUpdateDefault
>({
  createAttachmentQuery,
  createAttachmentKey,
  deleteAttachmentQuery,
  deleteAttachmentKey,
  updateAttachmentQuery,
  updateAttachmentKey,
  idKey,
  uploadKey,
  availableTags = [],
  defaultFiles,
  defaultMedia,
}: IuseAttachmentProps) => {
  const [files, setFiles] = useState<IAttachmentLandingZoneState<DocumentItem, AttachmentItem>>({
    files: defaultFiles || [],
    media: defaultMedia || [],
  });

  const { mutateAsync: createAttachment } = useGraphMutation<ReturnCreation, Error, InputCreation>({
    gql: createAttachmentQuery,
    key: createAttachmentKey,
  });

  const { mutateAsync: deleteAttachment } = useGraphMutation<any, Error, InputDelete>({
    gql: deleteAttachmentQuery,
    key: deleteAttachmentKey,
  });

  const { mutateAsync: UpdateAttachmentRequest } = useGraphMutation<any, Error, InputUpdate>({
    gql: updateAttachmentQuery,
    key: updateAttachmentKey,
    retry: 5,
  });

  /* Handle the files used in the FileLandingZone Component  */
  const handleFileChange = useCallback(
    (state: IAttachmentLandingZoneState<DocumentItem, AttachmentItem>) => {
      setFiles({ files: [...state.files], media: [...state.media] });
    },
    []
  );

  /* Return two arrays one with all the files with the selected field as true and the other one with the remaining items  */
  const getSelectedFile = useCallback((allFiles: TAttachmentLandingZoneFile<DocumentItem>[]) => {
    const selectedFile: TAttachmentLandingZoneFile<DocumentItem>[] = [];
    const unSelectedFiles: TAttachmentLandingZoneFile<DocumentItem>[] = [];

    allFiles.forEach((val) => {
      if (val.selectedFile === true) {
        selectedFile.push(val);
      } else {
        unSelectedFiles.push(val);
      }
    });
    return [selectedFile, unSelectedFiles];
  }, []);

  /* Use a array of files(documents) and array of ids to fuse them using the index as reference */
  const buildFilesWithIds = useCallback(
    (allFiles: TAttachmentLandingZoneFile<DocumentItem>[], ids: Array<string>) => {
      const convertedFiles: TAttachmentLandingZoneFile<DocumentItem>[] = [];

      allFiles.forEach((val, index) => {
        const currentFile = val;
        currentFile.id = ids[index];
        convertedFiles.push(currentFile);
      });
      return convertedFiles;
    },
    []
  );

  /* Use a array of files(documents) and array of ids to fuse them using the index as reference */
  const buildMediaFilesWithIds = useCallback(
    (allFiles: TAttachmentLandingZoneFile<AttachmentItem>[], ids: Array<string>) => {
      const convertedFiles: TAttachmentLandingZoneFile<AttachmentItem>[] = [];

      allFiles.forEach((val, index) => {
        const currentFile = val;
        currentFile.id = ids[index];
        convertedFiles.push(currentFile);
      });
      return convertedFiles;
    },
    []
  );

  /* Build the tag object used to know the selected file and also this can be used to create other tags */
  const buildTagForApi = (
    file: TAttachmentLandingZoneFile<DocumentItem | AttachmentItem>,
    key: Array<TFlagsKey>
  ) => {
    const tag: TFlagAttachments[] = [];

    key.forEach((currentKey) => {
      switch (currentKey) {
        case 'selected':
          tag.push({
            key: 'importantTag',
            value: file.selectedFile ? 'Yes' : 'No',
          });
          break;
        case 'order':
          tag.push({
            key: 'orderTag',
            value: `${file.order}`,
          });
          break;
        default:
          break;
      }
    });
    return tag;
  };

  /* Search in all the doc files the ones that doesn't have a ID , these ones are going to be created   */
  const getFilesToCreate = useCallback((file: TAttachmentLandingZoneFile<DocumentItem>[]) => {
    let finalArray: any[] = [];
    file.forEach((val) => {
      if (!Object.hasOwn(val, 'id')) finalArray = [...finalArray, val];
    });
    return finalArray;
  }, []);

  /* Search in all the media files the ones that doesn't have a "original" object , these ones are going to be created   */
  const getMediaToCreate = useCallback((file: TAttachmentLandingZoneFile<AttachmentItem>[]) => {
    let finalArray: any[] = [];
    file.forEach((val) => {
      if (!Object.hasOwn(val, 'original')) finalArray = [...finalArray, val];
    });
    return finalArray;
  }, []);

  /* Search in all the doc files the ones that have a ID , these ones are going to be mantained and also doesn't have the property of "removed" */
  const getFilesToMantain = useCallback((file: TAttachmentLandingZoneFile<DocumentItem>[]) => {
    let finalArray: any[] = [];
    file.forEach((val) => {
      if (Object.hasOwn(val, 'id') && !Object.hasOwn(val, 'removed'))
        finalArray = [...finalArray, val];
    });
    return finalArray;
  }, []);

  /* Search in all the media files the ones that have a "original" object , these ones are going to be mantained and also doesn't have the property of "removed" */
  const getMediaToMantain = useCallback((file: TAttachmentLandingZoneFile<AttachmentItem>[]) => {
    let finalArray: any[] = [];
    file.forEach((val) => {
      if (Object.hasOwn(val, 'original') && !Object.hasOwn(val, 'removed'))
        finalArray = [...finalArray, val];
    });
    return finalArray;
  }, []);

  /*  Search in the docs array for the ones with the removed property in true */
  const getFilesToDelete = useCallback((allFiles: TAttachmentLandingZoneFile<DocumentItem>[]) => {
    const cleanupFiles: TAttachmentLandingZoneFile<DocumentItem>[] = [];
    allFiles.forEach((val) => {
      if (val.removed) cleanupFiles.push(val);
    });
    return cleanupFiles;
  }, []);

  /* Search in the media array for the ones with the removed property in true  */
  const getMediaToDelete = useCallback((allFiles: TAttachmentLandingZoneFile<AttachmentItem>[]) => {
    const cleanupFiles: TAttachmentLandingZoneFile<AttachmentItem>[] = [];
    allFiles.forEach((val) => {
      if (val.removed) cleanupFiles.push(val);
    });
    return cleanupFiles;
  }, []);

  /* Make a Array of promises to get all the upload links for the attachments  */
  const getAlluploadLinks = useCallback(
    async (
      fileArray: TAttachmentLandingZoneFile<DocumentItem | AttachmentItem>[],
      id: string,
      extraVariables: { [x: string]: string } = {}
    ) => {
      let result: Error | ReturnCreation[];
      try {
        result = await Promise.all(
          fileArray.map(({ name: fileName }) =>
            createAttachment({
              input: { [idKey]: id, fileName, ...extraVariables },
            } as InputCreation)
          )
        );
      } catch (err) {
        result = err as Error;
      }
      return result;
    },
    [createAttachment, idKey]
  );

  /* Use the uploadLinks to upload all the files to the backend , fileArray is for docs and fileMedia is for media */
  const uploadFiles = useCallback(
    async (
      fileArray: TAttachmentLandingZoneFile<DocumentItem>[],
      fileMedia: TAttachmentLandingZoneFile<AttachmentItem>[],
      id: string,
      extraVariables: { [x: string]: string } = {}
    ) => {
      try {
        const allUploadLinks = await getAlluploadLinks(fileArray, id, extraVariables);
        const allUploadLinksMedia = await getAlluploadLinks(fileMedia, id, extraVariables);

        let uploadLinks: ReturnCreation[] = [];

        if (Array.isArray(allUploadLinks)) {
          uploadLinks = allUploadLinks;
          const uploadAllData = allUploadLinks.map((res, index) => {
            const { headers, link } = res[uploadKey];
            const formData = new FormData();
            headers.map(({ name, value }: { name: string; value: any }) =>
              formData.append(name, value)
            );
            formData.append('file', fileArray[index] as File);

            return fetch(link, {
              method: 'POST',
              body: formData,
            });
          });

          try {
            await Promise.all(uploadAllData);
          } catch (err) {
            throw new Error('Attachment files fails');
          }
        }

        let uploadLinksMedia: ReturnCreation[] = [];
        if (Array.isArray(allUploadLinksMedia)) {
          uploadLinksMedia = allUploadLinksMedia;
          const uploadAllData = allUploadLinksMedia.map((res, index) => {
            const { headers, link } = res[uploadKey];
            const formData = new FormData();
            headers.map(({ name, value }: { name: string; value: any }) =>
              formData.append(name, value)
            );
            formData.append('file', fileMedia[index] as File);

            return fetch(link, {
              method: 'POST',
              body: formData,
            });
          });

          try {
            await Promise.all(uploadAllData);
          } catch (err) {
            throw new Error('Attachment media fails');
          }
        }
        return { uploadLinks, uploadLinksMedia };
      } catch (ex: any) {
        throw new Error('Attachment fails');
      }
    },
    [getAlluploadLinks, uploadKey]
  );

  /* Make a Array of promises to delete all the files send in fileArray   */
  const deleteFiles = useCallback(
    async (
      fileArray: TAttachmentLandingZoneFile<DocumentItem>[],
      id: string,
      extraVariables: { [x: string]: string } = {}
    ) => {
      try {
        const result = await Promise.all(
          fileArray.map(({ id: attachmentId }) =>
            deleteAttachment({
              input: { [idKey]: id, attachmentId, ...extraVariables },
            } as InputDelete)
          )
        );

        return result;
      } catch (ex: any) {
        throw new Error('Delete Attachment fails');
      }
    },
    [deleteAttachment, idKey]
  );

  /* Make a Array of promises to delete all the media send in fileArray   */
  const deleteMedia = useCallback(
    async (
      fileArray: TAttachmentLandingZoneFile<AttachmentItem>[],
      id: string,
      extraVariables: { [x: string]: string } = {}
    ) => {
      try {
        const result = await Promise.all(
          fileArray.map(({ original }) =>
            deleteAttachment({
              input: { [idKey]: id, attachmentId: original.original.id, ...extraVariables },
            } as InputDelete)
          )
        );

        return result;
      } catch (ex: any) {
        throw new Error('Delete Attachment fails');
      }
    },
    [deleteAttachment, idKey]
  );

  /* Updated the attachment returning a Array of promises of the files (docs) to be updated */
  const updateAttachment = useCallback(
    async (
      fileArray: TAttachmentLandingZoneFile<DocumentItem | AttachmentItem>[],
      id: string,
      extraVariables: { [x: string]: string } = {}
    ) => {
      try {
        const result = await Promise.all(
          fileArray.map((file) =>
            UpdateAttachmentRequest({
              input: {
                [idKey]: id,
                attachmentId: file.id!,
                tags: buildTagForApi(file, availableTags),
                ...extraVariables,
              },
            } as InputUpdate)
          )
        );
        return result;
      } catch (ex: any) {
        throw new Error('Update Attachment fails');
      }
    },
    [UpdateAttachmentRequest, idKey, availableTags]
  );

  /* Create/Delete/Update all the files(docs) in edit mode */
  const editFiles = useCallback(
    async ({
      filesToCreate,
      filesToMaintain,
      filesToDelete,
      id,
      extraVariables = {},
    }: {
      filesToCreate: TAttachmentLandingZoneFile<DocumentItem>[];
      filesToMaintain: TAttachmentLandingZoneFile<DocumentItem>[];
      filesToDelete: TAttachmentLandingZoneFile<DocumentItem>[];
      id: string;
      extraVariables?: { [x: string]: string };
    }) => {
      let returnedFiles: ReturnCreation[] = [];
      try {
        if (filesToDelete.length > 0) {
          await deleteFiles(filesToDelete, id, extraVariables);
        }

        if (filesToMaintain.length > 0) {
          await updateAttachment(filesToMaintain, id, extraVariables);
        }

        if (filesToCreate.length > 0) {
          const { uploadLinks } = await uploadFiles(filesToCreate, [], id, extraVariables);
          if (Array.isArray(uploadLinks)) returnedFiles = uploadLinks;
          if (Array.isArray(returnedFiles)) {
            const attachmentsId = returnedFiles.map((res) => {
              const { id: currentId } = res[uploadKey];
              return currentId;
            });
            const filesWithIds = buildFilesWithIds(filesToCreate, attachmentsId);

            await updateAttachment(filesWithIds, id, extraVariables);
          }
        }
      } catch (error) {
        throw new Error('Something gone wrong editing Attachments');
      }
      return returnedFiles;
    },
    [uploadFiles, deleteFiles, buildFilesWithIds, updateAttachment, uploadKey]
  );

  const processEditFiles = useCallback(
    async (
      filesToProcess: TAttachmentLandingZoneFile<DocumentItem>[],
      id: string,
      extraVariables: { [x: string]: string } = {}
    ) => {
      const filesToCreate = getFilesToCreate(filesToProcess);
      const filesToMaintain = getFilesToMantain(filesToProcess);
      const filesToDelete = getFilesToDelete(filesToProcess);

      await editFiles({ filesToCreate, filesToMaintain, filesToDelete, id, extraVariables });
    },
    [editFiles, getFilesToCreate, getFilesToDelete, getFilesToMantain]
  );

  /* Create/Delete all the media in edit mode */
  const editMedia = useCallback(
    async (
      filesTocreate: TAttachmentLandingZoneFile<AttachmentItem>[],
      filesToDelete: TAttachmentLandingZoneFile<AttachmentItem>[],
      id: string,
      extraVariables: { [x: string]: string } = {}
    ) => {
      let returnedFiles: ReturnCreation[] = [];
      try {
        if (filesToDelete.length > 0) {
          await deleteMedia(filesToDelete, id, extraVariables);
        }

        if (filesTocreate.length > 0) {
          const { uploadLinksMedia } = await uploadFiles([], filesTocreate, id, extraVariables);
          if (Array.isArray(uploadLinksMedia)) returnedFiles = uploadLinksMedia;
          if (Array.isArray(returnedFiles)) {
            const attachmentsId = returnedFiles.map((res) => {
              const { id: currentId } = res[uploadKey];
              return currentId;
            });
            const filesWithIds = buildMediaFilesWithIds(filesTocreate, attachmentsId);
            await updateAttachment(filesWithIds, id, extraVariables);
          }
        }
      } catch (error) {
        throw new Error('Something gone wrong editing Attachments');
      }
      return returnedFiles;
    },
    [buildMediaFilesWithIds, deleteMedia, updateAttachment, uploadFiles, uploadKey]
  );

  const processEditMedia = useCallback(
    async (
      mediaToProcess: TAttachmentLandingZoneFile<AttachmentItem>[],
      id: string,
      extraVariables: { [x: string]: string } = {}
    ) => {
      const mediaToCreate = getMediaToCreate(mediaToProcess);
      const mediaToDelete = getMediaToDelete(mediaToProcess);

      await editMedia(mediaToCreate, mediaToDelete, id, extraVariables);
    },
    [editMedia, getMediaToCreate, getMediaToDelete]
  );

  const processEditAttachments = useCallback(
    async (
      filesToProcess: TAttachmentLandingZoneFile<DocumentItem>[],
      mediaToProcess: TAttachmentLandingZoneFile<AttachmentItem>[],
      id: string,
      extraVariables: { [x: string]: string } = {}
    ) => {
      await processEditFiles(filesToProcess, id, extraVariables);
      await processEditMedia(mediaToProcess, id, extraVariables);
    },
    [processEditFiles, processEditMedia]
  );

  return {
    getSelectedFile,
    buildFilesWithIds,
    uploadFiles,
    updateAttachment,
    deleteFiles,
    getFilesToCreate,
    getMediaToCreate,
    getFilesToMantain,
    getMediaToMantain,
    getFilesToDelete,
    getMediaToDelete,
    editFiles,
    editMedia,
    handleFileChange,
    setFiles,
    files,
    processEditFiles,
    processEditMedia,
    processEditAttachments,
  };
};
