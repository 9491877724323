// DEFAULT SETTINGS FOR CHARTS
export const chartSettings = Object.freeze({
  height: 250,
  width: 500,
});

export const legendColors = [
  '#685DAD',
  '#9FD784',
  '#AD5D5D',
  '#353B78',
  '#9D5DAD',
  '#ACA1EB',
  '#47573F',
  '#E47A7A',
  '#6872CC',
  '#512A5B',
  '#60894D',
  '#A6A1C6',
  '#511A1A',
  '#585A6D',
  '#DCB8E5',
];
