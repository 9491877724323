import React, { useState, useCallback } from 'react';
import { Button, useDownload } from '@esub-engineering/react-component-library';
import { CSVLink } from 'react-csv';
import { useToast } from '../useToast';
import { ExportToCSVProps, CSVHeader } from './types';
import { csvArrayStringify } from '../../utils/csvStringify';

export const useExportToCSV = ({
  entityName,
  headers,
  onClickCallback = () => {},
  buttonText = 'Export',
}: ExportToCSVProps) => {
  const [disabled, setDisabled] = useState(false);
  const [csvHeaders, setCSVHeaders] = useState<CSVHeader[] | string[]>(headers);
  const [csvData, setCSVData] = useState<any[]>([]); // nothing to export by default

  const { openSuccessToast, openErrorToast } = useToast();

  const { download } = useDownload();

  const handleCSVLink = useCallback(
    (event: any) => {
      if (!csvData.length) {
        event.preventDefault();
        return;
      }

      if (onClickCallback && typeof onClickCallback === 'function') {
        onClickCallback(setDisabled);
      }
    },
    [csvData, onClickCallback]
  );

  /**
   * Convert supplied data to csv and return encoded string
   * @param data array of arrays that represents data to be included in csv.
   * @example [['one', 'two', 'three'], ['one', 'two', 'three']]
   */
  const generateCSVEncodedURI = useCallback(
    (data: unknown[][]) => {
      let csvContent = `${csvHeaders.join(',')}\n`;

      data.forEach((row) => {
        csvContent += csvArrayStringify(row).join(',');
        csvContent += '\n';
      });

      return `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
    },
    [csvHeaders]
  );

  /**
   * Generate and download csv file from supplied data
   * @param data array of arrays that represents data to be included in csv.
   * @example [['one', 'two', 'three'], ['one', 'two', 'three']]
   */
  const downloadCSV = useCallback(
    (data: unknown[][]) => {
      // must have data and headers to process
      if (Array.isArray(data) && data.length && Array.isArray(csvHeaders) && csvHeaders.length) {
        try {
          // encode csv
          const csvEncodedURI = generateCSVEncodedURI(data);

          // trigger download of csv
          download({
            href: csvEncodedURI,
            filename: `exported_${entityName}.csv`,
          });

          openSuccessToast(`${entityName} successfully downloaded.`);
        } catch (e) {
          openErrorToast('Something went wrong, please try again.');
          console.error(e);
        }
      }
    },
    [csvHeaders, download, entityName, generateCSVEncodedURI, openSuccessToast, openErrorToast]
  );

  const CSVButton = React.memo(() => (
    <CSVLink
      onClick={handleCSVLink}
      headers={csvHeaders}
      data={csvData}
      filename={`${entityName}.csv`}
      style={{ textDecoration: 'none', flex: '50%', display: 'flex' }}
    >
      <Button
        id="ExportToCSVButton"
        type="button"
        color="primary"
        variant="contained"
        disabled={!csvData.length || disabled}
      >
        {buttonText}
      </Button>
    </CSVLink>
  ));

  return {
    CSVButton,
    csvData,
    setCSVData,
    setCSVHeaders,
    downloadCSV,
    disabled,
  };
};
